import * as React from 'react';
import { MainLayout } from 'components/MainLayout';
import { TextField } from 'components/TextField';
import { gql } from '@urql/core';
import { ContentTitle } from 'components/ContentTitle';
import { Controller, useForm, Resolver, FieldError, FieldErrors } from 'react-hook-form';
import { useBasicDeliveryPartnerPageMutation } from 'generated/graphql';
import { Footer } from 'components/Footer';
import { Link } from 'gatsby';
import { validateEmail, validatePhone } from 'utils';
import { ProgressButton } from '../components/ProgressButton';

enum InputNames {
  'phone',
  'email',
  'body',
}

type Input = {
  name: keyof typeof InputNames;
  label: string;
  placeholder?: string;
  required?: boolean;
  isTextarea?: boolean;
};

const inputs: Input[] = [
  {
    name: 'phone',
    label: 'Номер телефона',
    required: true,
    placeholder: 'Введите номер телефона',
  },
  {
    name: 'email',
    label: 'Электронная почта',
    required: true,
    placeholder: 'Введите электронную почту',
  },
  {
    name: 'body',
    label: 'Расскажите о себе',
    required: true,
    isTextarea: true,
  },
];

type FormValues = {
  phone: string;
  email: string;
  body: string;
};

const defaultValues: FormValues = {
  phone: '',
  email: '',
  body: '',
};

const Wrapper: React.FC = ({ children }) => (
  <MainLayout>
    <div className="container max-w-7xl mx-auto">
      <div className="pb-7">
        <ContentTitle title="Стать партнером Family Friend" />
        {children}
      </div>
    </div>
    <Footer />
  </MainLayout>
);

const validateMessage = (message: string): boolean => message.length > 30;

type ErrorData = {
  field: keyof FormValues;
  error: FieldError;
};

type Validator = (values: FormValues) => ErrorData | null;

const validators: Validator[] = [
  (values) => {
    if (!validatePhone(values.phone)) {
      return {
        field: 'phone',
        error: {
          type: 'required',
          message: 'Некорректный телефон',
        },
      };
    }
    return null;
  },
  (values) => {
    if (!validateEmail(values.email)) {
      return {
        field: 'email',
        error: {
          type: 'required',
          message: 'Некорректный email',
        },
      };
    }
    return null;
  },
  (values) => {
    if (!validateMessage(values.body)) {
      return {
        field: 'body',
        error: {
          type: 'required',
          message: 'Вы не рассказали о себе',
        },
      };
    }
    return null;
  },
];

const resolver: Resolver<FormValues> = async (values) => {
  const errorData: ErrorData[] = [];
  validators.forEach((v) => {
    const ed = v(values);
    if (ed) {
      errorData.push(ed);
    }
  });

  if (errorData.length > 0) {
    const errors: FieldErrors<FormValues> = {};
    errorData.forEach((e) => {
      errors[e.field] = e.error;
    });
    return { errors, values: {} };
  }

  return { values, errors: {} };
};

const DeliveryPartnerPage = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues, resolver });
  const [mutationState, mutate] = useBasicDeliveryPartnerPageMutation();
  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutate({
        subject: 'Партнер',
        ...data,
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(e);
    }
  });

  if (mutationState.data?.result.__typename === 'CreateMessagePayload') {
    return (
      <Wrapper>
        <div className="py-7 max-w-lg mx-auto">
          <div className="px-7 mb-6 text-xl font-medium text-center">
            Спасибо за интерес к компании Family Friend. <br />В течение одного рабочего дня вам придет ответ на
            указанную почту.
          </div>
          <Link
            to="/"
            className="uppercase font-medium text-center px-5 rounded-md block m-auto mt-7 bg-orange-200 w-56 py-4 text-white mb-6"
          >
            На главную
          </Link>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <form onSubmit={onSubmit} className="py-7 max-w-lg mx-auto lg:py-3 px-2">
        <div className="text-center px-7 mb-5 text-xl font-medium lg:text-base lg:mb-1">
          Хотите стать нашим партнером?
          <br />
          Оставьте заявку ниже
        </div>
        {inputs.map((params) => {
          const { isTextarea, name, label, placeholder, required } = params;
          return (
            <div className="max-w-[500px] mx-auto" key={params.name}>
              <Controller
                key={name}
                control={control}
                name={name}
                render={({ field: { value, onChange } }) => (
                  <>
                    {isTextarea ? (
                      <label className="block w-full py-2" htmlFor={name}>
                        <span className="relative lg:text-sm">
                          Опишите ваш бизнес
                          <span style={{ color: 'red' }} className="absolute -top-1 -right-3 text-red-50">
                            *
                          </span>
                        </span>
                        <textarea
                          id={name}
                          style={{ color: '#181818', minHeight: 142, borderWidth: 1 }}
                          className="text-base resize-y  w-full rounded-md py-3 px-4 placeholder-gray-50 mt-2 bg-gray-600  border-transparent transition-colors focus:border-cyan-300 lg:py-2"
                          placeholder={placeholder}
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {errors[name]?.message && <div className="text-red-50 text-sm">{errors[name]?.message}</div>}
                      </label>
                    ) : (
                      <TextField
                        required={required}
                        inputClassNames="py-3 px-4 my-2 lg:py-2 lg:my-1"
                        label={label}
                        error={errors[name]?.message}
                        placeholder={placeholder}
                        onChangeText={(val: string) => onChange(val)}
                      />
                    )}
                  </>
                )}
              />
            </div>
          );
        })}
        <ProgressButton
          type="submit"
          isProgress={mutationState.fetching}
          styles="btn btn-orange mt-5 mx-auto items-center justify-center flex-col overflow-hidden mt-7 mb-6"
        >
          Отправить
        </ProgressButton>
        <div className="text-xs italic text-gray-50 text-center">
          Нажимая на кнопку, вы даете согласие на обработку своих персональных данных
        </div>
      </form>
    </Wrapper>
  );
};

export default DeliveryPartnerPage;

gql`
  mutation PartnerPageMutation($input: CreateMessageInput!) {
    result: createMessage(input: $input) {
      ... on CreateMessagePayload {
        data: message {
          body
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
